import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import media from "../../asset/blake-wisz-TcgASSD5G04-unsplash.jpg";
import {LeafletMarker} from "../../types/domain";
import {useReservation} from "../../context/reservation-context/reservation-context";
import IconButton from "@material-ui/core/IconButton";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";
import {Box} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  },
  media: {
    height: 140
  }
});

interface VendorCardProps {
  data: LeafletMarker;
}

const VendorCard: React.FC<VendorCardProps> = ({data}) => {
  const {makeReservation} = useReservation();
  const classes = useStyles();

  return (
      <Card className={classes.root} elevation={0}>
        <CardMedia
            className={classes.media}
            image={media}
            title="Camping Stock photo"
        />
        <CardContent>
          <Box style={{display: "flex", alignItems: "flex-start"}}>
            <Typography
                gutterBottom
                variant="h5"
                component="h2"
                style={{flexGrow: 1}}
            >
              {data.name}
            </Typography>
            <IconButton size="small">
              <LaunchOutlinedIcon
                  onClick={() => window.open(data.businessUrl, "_blank")}
              />
            </IconButton>
          </Box>
          <Typography variant="body2" color="textSecondary" component="p">
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            tristique et risus eu tristique."
          </Typography>
        </CardContent>
        <CardActions>
          <Button
              onClick={() => makeReservation(data.reservationUrl)}
              size="large"
              color="primary"
              variant="contained"
              style={{width: "100%"}}
          >
            reservieren
          </Button>
        </CardActions>
      </Card>
  );
};

export default VendorCard;
