import {labels} from "../service/fixture/reservation-object.json";

export type LabelType = typeof labels;

const transformQueryString = (key: string, object: LabelType) => {
    switch (key) {
        case "ort":
            return object.ort;
        case "von":
            return object.von;
        case "bis":
            return object.bis;
        default:
            return key;
    }
};

export const buildQueryString = (fields: any): string => {
    const keys: string[] = Object.keys(fields);
    const last: string = keys[keys.length - 1];

    let str = "?";

    keys.forEach(key => {
        const label = transformQueryString(key, labels);

        if (fields[key] && key !== "ort") {
            str += `${label}=${fields[key]}`;
            if (key !== last) {
                str += "&";
            }
        }
    });
    return str;
};
