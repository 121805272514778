import React from "react";
import L from "leaflet";
import {Marker, Popup} from "react-leaflet";
import {LeafletMarker} from "../../types/domain";
import VendorCard from "../vendor-card/vendor-card";

interface VendorMarkersProps {
  markers: LeafletMarker[];
  icon: L.Icon;
}

const VendorMarkers: React.FC<VendorMarkersProps> = ({markers, icon}) => {
  return (
      <React.Fragment>
        {markers.map((location, index) => {
          const markerPosition = new L.LatLng(location.lat, location.lng);
          return (
              <Marker
                  key={index}
                  icon={icon}
                  position={markerPosition}
                  {...location}
              >
                <Popup keepInView={false} closeButton={false}>
                  <VendorCard data={location}/>
                </Popup>
              </Marker>
          );
        })}
      </React.Fragment>
  );
};

export default VendorMarkers;
