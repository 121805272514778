import React from "react";
import {buildQueryString} from "../../lib/build-query-string";
import {addDays, format} from "date-fns";

/** Context **/
interface ReservationContextProps {
  fields: {
    ort: string;
    von: string;
    bis: string;
  };
  updateFields: (foo: any) => void;
  makeReservation: (foo: any) => void;
}

const defaultValues = {
  fields: {
    ort: "Cheimsee",
    von: format(new Date(), "yyyy-MM-dd"),
    bis: format(addDays(new Date(), 3), "yyyy-MM-dd")
  },
  updateFields: () => {
  },
  makeReservation: () => {
  }
};

const ReservationContext = React.createContext<ReservationContextProps>(
    defaultValues
);

/** Provider **/
interface ReservationContextProviderProps {
  children: React.ReactNode;
  defaultValue?: ReservationContextProps;
}

export const ReservationContextProvider: React.FC<ReservationContextProviderProps> = ({children, defaultValue = defaultValues}) => {
  const [values, setValues] = React.useState({
    ort: defaultValue ? defaultValue.fields.ort : "", //Chiemsee
    von: defaultValue ? defaultValue.fields.von : "YYYY.TT.MM", // TT.MM.YYYY
    bis: defaultValue ? defaultValue.fields.bis : "YYYY.TT.MM" // TT.MM.YYYY
  });

  const openLink = (link: string) => {
    const params = buildQueryString(values);
    window.location.href = link + params;
  };

  return (
      <ReservationContext.Provider
          value={{
            fields: {
              ort: values.ort,
              von: values.von,
              bis: values.bis
            },
            updateFields: setValues,
            makeReservation: openLink
          }}
      >
        {children}
      </ReservationContext.Provider>
  );
};

/** Hook **/
export const useReservation = () => {
  return React.useContext(ReservationContext);
};
