import React from "react";
import ReactDOM from "react-dom";
import "./style/index.css";
import App from "./component/app/app";
import {ContextProviders} from "./context/context-providers";
import * as serviceWorker from "./serviceWorker";
import {ThemeProvider} from "@material-ui/core";
import {theme} from "./lib/theme";
import CssBaseline from "@material-ui/core/CssBaseline";

ReactDOM.render(
    <React.StrictMode>
        <ContextProviders>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <App/>
            </ThemeProvider>
        </ContextProviders>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
