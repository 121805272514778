import React from "react";

/** Context **/
interface LocationContextProps {
    center: {
        lat: number;
        lng: number;
    };
    zoom: number;
    error: string
}

const defaultValues = {
    center: {
        lat: 52.520007,
        lng: 13.404954
    },
    zoom: 14,
    error: ""
};

const LocationContext = React.createContext<LocationContextProps>(
    defaultValues
);

/** Provider **/
interface LocationContextProviderProps {
    children: React.ReactNode;
    defaultValue?: LocationContextProps;
}

export const LocationContextProvider: React.FC<LocationContextProviderProps> = ({children, defaultValue = defaultValues}) => {
    const geoLocation = navigator.geolocation;
    const [center, setCenter] = React.useState(defaultValue?.center)
    const [zoom] = React.useState(defaultValue?.zoom)
    const [error, setError] = React.useState(defaultValue?.error)


    const successHandler = ({coords}: any) => {
        setCenter({
            lat: coords.latitude,
            lng: coords.longitude
        });
    }

    const errorHandler = (err: any) => {
        setError(err.message)
    }

    React.useEffect(() => {
        if (!geoLocation) {
            setError("Geolocation is not supported");
            return;
        }
        const watcher = geoLocation.watchPosition(successHandler,
            errorHandler
            , {enableHighAccuracy: true})

        return () => geoLocation.clearWatch(watcher);
    }, [geoLocation]);

    return (
        <LocationContext.Provider value={{center, zoom, error}}>
            {children}
        </LocationContext.Provider>
    );
};

/** Hook **/
export const useLocation = () => {
    return React.useContext(LocationContext);
};
