import React from "react";
import L from "leaflet";
import style from "./app.module.css";
import {LeafletMarker, ReservationFormState} from "../../types/domain";
import Box from "@material-ui/core/Box";
import {Snackbar} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReservationForm from "../reservation-form/reservation-form";
import LeafletMap from "../leaflet-map/leaflet-map";
import {useLocation} from "../../context/location-context/location-context";

const App = () => {
  const [isOpen, setOpenState] = React.useState<boolean>(true);
  const [markers, setMarkers] = React.useState<LeafletMarker[]>([]);

  const {center, zoom, error} = useLocation();
  const [currentLocation, setCurrentLocation] = React.useState<{
    lat: number;
    lng: number;
  }>(center);
  const [zoomValue, setZoomValue] = React.useState<number>(zoom);
  const [errorMessage, setErrorMessage] = React.useState<string>(error);

  const submitHandler = (locations: LeafletMarker[]) => {
    if (locations.length) {
      setMarkers(locations);

      const bounds = locations.map(location => {
        return L.latLng(location.lat, location.lng);
      });

      const viewPortBounds = L.latLngBounds(bounds).getCenter();
      const {lat, lng} = viewPortBounds;
      setCurrentLocation({lat, lng});
      setZoomValue(isOpen ? 11 : 14);
      setOpenState(!isOpen);
    }

  };

  return (
      <Box className={style.root}>
        <header className={style.header}>
          <ReservationForm open={isOpen} onSubmit={submitHandler}/>
        </header>
        <main className={style.main}>
          <LeafletMap
              center={currentLocation}
              zoom={zoomValue}
              markers={markers}
              onError={foo => alert(foo)}
              // onPositionChange={foo => console.log(foo)}
          />
        </main>
        <footer className={style.footer}>
          <Snackbar
              open={errorMessage.length > 0}
              autoHideDuration={6000}
              onClose={() => setErrorMessage("")}
              disableWindowBlurListener={true}
              message={error}
              action={
                <React.Fragment>
                  <IconButton
                      color="primary"
                      aria-label="close"
                      onClick={() => setErrorMessage("")}
                  >
                    <CloseIcon fontSize="small"/>
                  </IconButton>
                </React.Fragment>
              }
          />
        </footer>
      </Box>
  );
};

export default App;
