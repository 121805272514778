import React from "react";
import {LeafletMarker} from "../../types/domain";
import {Map, Marker, Popup, TileLayer} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {LocationMarker} from "./location-marker/location-marker";
import {VendorMarker} from "./vendor-marker/vendor-marker";
import VendorMarkers from "../vendor-markers/vendor-markers";

/** Component **/
interface LeafletMapProps {
    center: {
        lat: number;
        lng: number;
    };
    zoom: number;
    markers: LeafletMarker[];
  onError?: (msg: string) => void;
}

const LeafletMap: React.FC<LeafletMapProps> = ({
                                                   center,
                                                   zoom,
                                                   markers,
                                                   onError
                                               }) => {
    const [userLocation] = React.useState(center);

    const marker = center ? (
        <Marker icon={LocationMarker} position={userLocation}>
            <Popup>Du bis heir!</Popup>
        </Marker>
    ) : null;

    return (
        <Map animate={true} center={center} zoom={zoom}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {marker}
            {markers.length > 0 && (
                <VendorMarkers markers={markers} icon={VendorMarker}/>
            )}
        </Map>
    );
};

export default LeafletMap;
