import React from "react";
import {LocationContextProvider} from "./location-context/location-context";
import {ReservationContextProvider} from "./reservation-context/reservation-context";

interface ContextProvidersProps {
    children: React.ReactNode;
}

export const ContextProviders: React.FC<ContextProvidersProps> = ({
                                                                      children
                                                                  }) => (
    <React.Fragment>
        <LocationContextProvider>
            <ReservationContextProvider>
                <>{children}</>
            </ReservationContextProvider>
        </LocationContextProvider>
    </React.Fragment>
);
