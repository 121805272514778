import {DEFAULT_LOCATION} from "./constant";
import {LeafletMarker} from "../types/domain";

export const locationFilter = (ort: string, data: LeafletMarker[]) => {
  const destination = ort !== "" ? ort : DEFAULT_LOCATION;
  const filteredLocations = data.filter(
      item => item.location.toLowerCase().indexOf(destination.toLowerCase()) >= 0
  );
  return filteredLocations;
};
