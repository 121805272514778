import React from "react";
import cn from "classnames";
import style from "./reservation-form.module.css";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {useReservation} from "../../context/reservation-context/reservation-context";
import {addDays, format} from "date-fns";
import {LeafletMarker, ReservationFormState} from "../../types/domain";
import {locations} from "../../service/fixture/camp-data.json";
import {locationFilter} from "../../lib/location-filter";

interface ReservationFormProps {
  open: boolean;
  onSubmit: (values: LeafletMarker[]) => void;
}

const ReservationForm: React.FC<ReservationFormProps> = ({
                                                           open,
                                                           onSubmit
                                                         }) => {
  const {updateFields} = useReservation();
  const [values, setValues] = React.useState({
    ort: "",
    von: format(new Date(), "yyyy-MM-dd"),
    bis: format(addDays(new Date(), 3), "yyyy-MM-dd")
  });
  const defaultButtonLabel = "Verfügbarkeit";
  const [buttonLabel, setButtonLabel] = React.useState(defaultButtonLabel);

  const changeHandler = (name: keyof ReservationFormState) => (
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (name === "ort") {
      setButtonLabel(defaultButtonLabel);
    }
    setValues({...values, [name]: event.target.value});
  };

  const submitHandler = () => {
    const filteredLocations = locationFilter(values.ort, locations);
    setButtonLabel(
        buttonLabel !== defaultButtonLabel
            ? defaultButtonLabel
            : filteredLocations.length + " Verfügbarkeiten gefunden"
    );
    updateFields(values);
    onSubmit(filteredLocations);
  };

  React.useEffect(() => {
    updateFields({...values});
  }, [updateFields, values]);

  return (
      <Container className={cn(style.root, !open && style.closed)}>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
        >
          <Grid item className={style.branding} xs={12} md={2}>
            Platzhalter.io!
          </Grid>
          <Grid item xs={12} sm={4} md={2} className={style.formControl}>
            <TextField
                id="ort"
                className={style.inputField}
                defaultValue={values.ort}
                label="Ort:"
                onChange={changeHandler("ort")}
                margin="normal"
                variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2} className={style.formControl}>
            <TextField
                id="von"
                className={style.inputField}
                defaultValue={values.von}
                label="von: (JJJJ-MM-DD)"
                onChange={changeHandler("von")}
                margin="normal"
                variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2} className={style.formControl}>
            <TextField
                id="bis"
                className={style.inputField}
                defaultValue={values.bis}
                label="bis: (JJJJ-MM-DD)"
                onChange={changeHandler("bis")}
                margin="normal"
                variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4} className={style.cta}>
            <Button
                className={style.button}
                variant="contained"
                color="primary"
                onClick={submitHandler}
            >
              {buttonLabel}
            </Button>
          </Grid>
        </Grid>
      </Container>
  );
};

export default ReservationForm;
